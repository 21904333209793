import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        primary: { dark: '#11274B', main: '#2b4061', light: 'rgb(236, 242, 251)', extraLight: 'rgb(247, 251, 254)' },
        secondary: { dark: '#cd9a37', main: '#F3BD48' },
        // lightGray: "#F3F5F8",
        // success: '#00FF00',
        // warning: '#FF0000',
        // textOnPrimary: '#FFF',
        // textOnSurface: '#000',
        // link: "#2783D4",
        // highlight: '#cee4e5'
    },
    widths: {
        drawerWidth: 180
    },
    overrides: {
        MuiInput: {
            root: {
                borderRadius: 0,
                // backgroundColor: "#fff",
                fontFamily: `'Press Start 2P', cursive;`,
                fontSize: 16,
            },
        },
    }
});
