import { Typography } from '@material-ui/core';
import React from 'react';
const pack = require('../../package.json')

const CommonFooter = () => {

  return (
    <main>
      <Typography variant="body2" style={{color: "#FFF", padding: '8px 16px'}}>
        &copy; {pack.description} {(new Date().getFullYear())}
      </Typography>
    </main>
  )
}
export default CommonFooter
