import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import theme from '../assets/themes/theme';
import GuestLayout from './guestLayout';
import './app.css';
import CommonFooter from './commonFooter';

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
        <BrowserRouter>
          <GuestLayout />
        </BrowserRouter>
        {/* <CommonFooter />      */}
    </MuiThemeProvider>
  )
}

const mapState = (state) => {
  return {
  }
}
export default connect(mapState)(App)
