import React, { useState } from 'react';
import { Button, makeStyles, Typography, TextField } from '@material-ui/core';
import { fonts } from '../../assets/fonts';
import { colors } from '../../assets/colors';
import SocialSection from '../../components/SocialSection';
import BitBackground from './assets/bit-background.svg';
import BitBackgroundMobile from './assets/bit-background-mobile.svg';
import BitBanner from './assets/bitBanner.svg';


const HomePage = () => {
    const classes = useStyles()
    const [emailValue, setEmailValue] = useState('')

    const handleChange = (e) => {
        setEmailValue(e.target.value)
    }
    return (
        <div className={classes.container}>
            <img src={BitBanner} className={classes.bitBanner} />
            {/* <Typography className={classes.headerText}>
                {'Sign up to be notified when we drop!'}
            </Typography>
            <div className={classes.formWrapper}>
                <TextField
                    label={'Enter Email'}
                    required
                    className={classes.textInput}
                    onChange={handleChange}
                    name={'email'}
                    value={emailValue}
                    // error={error}
                    type={'text'}
                    InputProps={{
                        disableUnderline: true,
                        style: { paddingLeft: 8 },
                    }}
                />
                <Button className={classes.submitBtn}>
                    SIGN UP
                </Button>
            </div> */}
            <SocialSection style={{ marginTop: 58 }} />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundImage: `url(${BitBackground})`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        '& .MuiInputLabel-formControl': {
            color: colors.purple,
            fontWeight: 400,
            paddingLeft: 8,
            fontFamily: fonts.PressStart,
            [theme.breakpoints.down('sm')]:{
                fontSize: 14
            }
          },
          '& .MuiInputLabel-shrink': {
            display: 'initial',
            marginLeft: 4,
            paddingRight: 6,
            color: colors.purple,
            backgroundColor: colors.white,
          },
          '& .MuiFormLabel-asterisk': {
            color: colors.orangeyRed,
          },
          [theme.breakpoints.down('xs')]:{
              backgroundImage: `url(${BitBackgroundMobile})`,
          }
    },
    bitBanner: {
        maxWidth: 498, 
        width: '100%',
        height: 328,
        objectFit: 'cover',
        // marginBottom: 62,
        [theme.breakpoints.down('sm')]:{
            maxWidth: 220,
            height: 145,
            // marginBottom: 26,
        }
    },
    headerText: {
        fontFamily: fonts.PressStart,
        fontSize: 21,
        color: colors.white,
        textShadow: `0 0 10px #fff, 0 0 20px #fff, 0 0 30px ${colors.purple}, 0 0 40px ${colors.purple}, 0 0 50px ${colors.purple}, 0 0 60px ${colors.purple}, 0 0 70px ${colors.purple};`,
        marginBottom: 42,
        [theme.breakpoints.down('sm')]:{
            fontSize: 14,
            textAlign: 'center',
        }
    },
    formWrapper: {
        display: 'flex', 
        flexDirection: 'space-between', 
        width: '100%', 
        maxWidth: 650,
        [theme.breakpoints.down('sm')]:{
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    textInput: {
        maxWidth: 422,
        height: 60,
        width: '100%',
        backgroundColor: colors.white,
        color: colors.purple,
        fontSize: 17,
        fontFamily: fonts.PressStart,
        [theme.breakpoints.down('sm')]:{
            maxWidth: 268,
            height: 42,
            fontSize: 14,
        }
    },
    submitBtn: {
        backgroundColor: colors.purple,
        color: colors.white,
        fontSize: 16,
        fontFamily: fonts.PressStart,
        maxWidth: 202,
        width: '100%',
        height: 61,
        marginLeft: 24,
        [theme.breakpoints.down('sm')]:{
            maxWidth: 186,
            height: 46,
            fontSize: 15,
            marginTop: 26
        }
    }
}))

export default HomePage;