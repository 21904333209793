import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from '../pages/Home';
import { paths } from '../utils/paths';


const GuestLayout = () => {
  return (
    <div>
      <Switch>
        <Route path={paths.root} exact component={Home} />
        <Redirect to={paths.root} />
      </Switch>   
    </div>
  )
}

export default GuestLayout
